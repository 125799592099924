import {basicSetup} from 'codemirror';
import {EditorState} from '@codemirror/state';
import {EditorView, keymap} from '@codemirror/view';
import {acceptCompletion} from '@codemirror/autocomplete';
import {expressionlanguage} from '@valtzu/codemirror-lang-el';
import {defaultKeymap} from '@codemirror/commands';

export default (Alpine) => {

    Alpine.data('codeEditorFormComponent', (state, isReadOnly, elConfig) => {
        return {
            state,
            editor: undefined,
            isReadOnly: false,
            elConfig: {},
            init: function() {
                this.isReadOnly = isReadOnly;
                this.elConfig = elConfig;
                this.render();
            },
            render() {

                this.editor = new EditorView({
                    state: EditorState.create({
                        extensions: [
                            basicSetup,
                            keymap.of([
                                ...defaultKeymap,
                                {key: 'Tab', run: acceptCompletion}]),
                            expressionlanguage(this.elConfig),
                            EditorView.lineWrapping,
                            EditorView.updateListener.of((v) => {
                                if (v.docChanged) {
                                    this.state = v.state.doc.toString();
                                }
                            }),
                        ],
                        doc: this.state,
                    }),
                    parent: this.$refs.codeEditor,
                });
            },
        };
    });
}
